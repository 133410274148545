import { configureStore } from "@reduxjs/toolkit";

import generalReducer from "../features/ganeral.slide";

export const store = configureStore({
  reducer: {
    general: generalReducer,
  },
});

export default store;
