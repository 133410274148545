import "./home.css";
import box from "../../images/box.avif";
import hands from "../../images/hands.jpg";
import logo from "../../images/logo.png";

import { useState } from "react";

export const Home = () => {
  const aboutUs = [
    {
      id: 1,
      title: "Our Mission",
      text: "At Tanama Prime, our mission is clear and focused: to provide cutting-edge logistics solutions, delivering fast and effective results. We are dedicated to optimizing our clients' requirements, ensuring unparalleled efficiency at every step of the supply chain.",
      img: box,
    },
    {
      id: 2,
      title: "Our Commitment",
      text: "We take pride in being strategic partners to our clients, working tirelessly to maximize their operational efficiency and reduce costs. At  Tanama Prime, we don't just deliver logistics; we deliver the future of logistics.",
      img: hands,
    },
  ];

  const contact = [
    {
      id: 1,
      name: "name",
      placeholder: "enter your name",
      type: "text",
    },
    {
      id: 2,
      name: "email",
      placeholder: "enter your email",
      type: "email",
    },
    {
      id: 3,
      name: "phone",
      placeholder: "enter your phone",
      type: "number",
    },
    // {
    //   id: 4,
    //   name: "Message",
    //   placeholder: "Enter your name",
    //   type:"text"
    // },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Simulamos un tiempo de espera de 3 segundos antes de mostrar "Enviado" y limpiar el formulario
    setTimeout(() => {
      setIsSent(true);
      setIsLoading(false);
      setFormData({ name: "", email: "", phone: "", message: "" });

      setTimeout(() => {
        setIsSent(false);
      }, 5000);
    }, 3000);
  };
  return (
    <div className="c-home">
      <div className="c-logo-container">
        <img src={logo} alt="Logo" />
      </div>

      <div className="c-hero-container">
        <p>Fast and effective logistics solutions</p>
      </div>

      <div className="c-about-us-container">
        {aboutUs.map((item) => {
          return (
            <div className="c-about-us" key={item.id}>
              <div className="c-about-us-img">
                <img src={item.img} alt={item.title} />
              </div>
              <h3>{item.title}</h3>
              <p> {item.text}</p>
            </div>
          );
        })}
      </div>

      <div className="c-contact-form-container">
        <h3> Get in touch</h3>
        <form onSubmit={handleSubmit}>
          {contact.map((item) => {
            return (
              <label htmlFor="" key={item.id}>
                <span>{item.name}</span>
                <input
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  value={formData[item.name]}
                  onChange={handleInputChange}
                  required
                />
              </label>
            );
          })}
          <label className="c-contact-form-textarea">
            <span>Message</span>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="enter your message"
            ></textarea>
          </label>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Sending..." : "send"}
          </button>
        </form>
        {isSent && <p className="c-contact-message">message sent successfully</p>}
      </div>

      <div className="c-footer-container">
        <div className="c-footer-info">
          <span>
            Addres:
            <a href="https://maps.app.goo.gl/evWCRQjyrDdmvFko6" target="_blank" rel="noreferrer">
              8350 NW 52 Ter Suite 301 Doral Fl. 33166
            </a>
          </span>
          <span>
            Phone:
            <a href="tel:17864777598" target="_blank" rel="noreferrer">
              +1 786-477-7598
            </a>
          </span>
        </div>
        {/* <p>Miami, Florida, United States</p> */}
        <p>COPYRIGHT © 2023 TANAMA PRIME - ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
};
