import { createSlice } from "@reduxjs/toolkit";

export const generalSlide = createSlice({
  name: "general",
  initialState: {
    test: "test",
  },
  reducers: {
    handleTest: (state, action) => {
      state.test = action.payload;
    },
  },
});

export const { handleTest } = generalSlide.actions;
export default generalSlide.reducer;
