import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../pages/home/Home";

function PublicRoutes() {
  return (
    <Routes>
      {/* HOME */}
      <Route path={"/"} element={<Home />} />

      {/* REDIRECT TO HOME */}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default PublicRoutes;
